import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { useDataContext } from "../context/DataContext.tsx";
import LoginPopup from "../components/Popup/LoginPopup.tsx";
import RiskifiedMetrics from "../pages/RiskifiedMetrics/RiskifiedMetrics.tsx";
import BlueshiftMetrics from "../pages/BlueshiftMetrics/BlueshiftMetrics.tsx";
import InkleMetrics from "../pages/InkleMetrics/InkleMetrics.tsx";
import Unauthorized from "../pages/Unauthorized/Unauthorized.tsx";
import NotFound from "../pages/NotFound/NotFound.tsx";
import TermsOfUsage from "../pages/TermsOfUsage/TermsOfUsage.tsx";
import PrivacyPolicy from "../pages/PrivacyPolicy/PrivacyPolicy.tsx";
import Welcome from "../pages/Welcome/Welcome.tsx";
import Home from "../pages/Home/Home.tsx";
import ProtectedRoute from "./ProtectedRoute.tsx";

const AllRoutes = () => {
  const { user } = useDataContext();

  // const ProtectedRoute = ({ children, allowedRoles }: { children: React.ReactNode, allowedRoles: string[] }) => {
  //   // If no user or empty token, redirect to login
  //   if (!user?.token) {
  //     return <Navigate to="/" />;
  //   }

  //   // If user has a role but not authorized for this route
  //   if (user.role && !allowedRoles.includes(user.role)) {
  //     return <Navigate to="/unauthorized" />;
  //   }

  //   return <>{children}</>;
  // };

  return (
    <Routes>
      {/* Public Routes */}
      <Route path="/" element={!user?.token ? <LoginPopup /> : <Welcome />} />
      <Route path="/terms-of-use" element={<TermsOfUsage />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/unauthorized" element={<Unauthorized />} />

      {/* Protected Routes */}
      <Route
        path="/riskified"
        element={
          <ProtectedRoute allowedRoles={["webless_admin", "riskified_admin", "riskified_user"]}>
            <Home />
          </ProtectedRoute>
        }
      />
      <Route
        path="/riskified-metrics"
        element={
          <ProtectedRoute allowedRoles={["webless_admin", "riskified_admin", "riskified_user"]}>
            <RiskifiedMetrics />
          </ProtectedRoute>
        }
      />
      <Route
        path="/blueshift-metrics"
        element={
          <ProtectedRoute allowedRoles={["webless_admin", "blueshift_admin", "blueshift_user"]}>
            <BlueshiftMetrics />
          </ProtectedRoute>
        }
      />
      <Route
        path="/inkle-metrics"
        element={
          <ProtectedRoute allowedRoles={["webless_admin", "inkle_admin", "inkle_user"]}>
            <InkleMetrics />
          </ProtectedRoute>
        }
      />

      {/* 404 Route - Must be last */}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default AllRoutes;
