import React from "react";
import { MetricsDashboard } from "../../components/MetricsDashboard/MetricsDashboard.tsx";
import "../../components/MetricsDashboard/MetricsDashboard.css";

const DASHBOARD_ID = "aa81d376-7822-4a30-a68b-6361d6b2baed";

const RiskifiedMetrics: React.FC = () => {
  return (
    <MetricsDashboard
      dashboardId={DASHBOARD_ID}
      title="Riskified Metrics Dashboard"
      containerClassName="riskified-metrics-container"
    />
  );
};

export default RiskifiedMetrics;
