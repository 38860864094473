import React, { useState, useEffect } from "react";
import axios from "axios";
import "./MetricsDashboard.css";
import { useDataContext } from "../../context/DataContext.tsx";

interface MetricsDashboardProps {
  dashboardId: string;
  title: string;
  containerClassName?: string;
}

const LAMDA_URL = "https://seo4q7mc6sxihppl6o5fqzcmhe0rslus.lambda-url.us-west-1.on.aws";

const MetricsDashboard: React.FC<MetricsDashboardProps> = ({ 
  dashboardId, 
  title,
  containerClassName = "metrics-dashboard-container" 
}) => {
  const [embedUrl, setEmbedUrl] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const { user } = useDataContext();

  useEffect(() => {
    const link = document.querySelector("link[rel*='icon']") || document.createElement("link");
    link.type = "image/x-icon";
    link.rel = "shortcut icon";
    link.href = "/assets/images/webless_favicon.png";
    document.getElementsByTagName("head")[0].appendChild(link);
  }, []);

  useEffect(() => {
    const fetchEmbedUrl = async () => {
      try {
        const response = await axios.get(
          `${LAMDA_URL}?dashboard_id=${dashboardId}&token=${user.token}`,
          {
            headers: {
              "Content-Type": "application/json"
            }
          }
        );
        setEmbedUrl(response.data.embedUrl);
        setLoading(false);
      } catch (err) {
        setError("Failed to load dashboard URL");
        setLoading(false);
        console.error("Error fetching QuickSight URL:", err);
      }
    };

    if (user.token) {
      fetchEmbedUrl();
    }
  }, [user.token, dashboardId]);

  if (loading) {
    return (
      <div className={containerClassName}>
        <div className="loading-state">Loading dashboard...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className={containerClassName}>
        <div className="error-state">{error}</div>
      </div>
    );
  }

  return (
    <div className={containerClassName}>
      <iframe
        width="100%"
        height="100%"
        src={embedUrl}
        title={title}
        frameBorder="0"
      />
    </div>
  );
};

export { MetricsDashboard };
export default MetricsDashboard;
