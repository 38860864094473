import React from "react";
import { useNavigate } from "react-router-dom";
import "./WeblessNavbar.css";
import { GiHamburgerMenu } from "react-icons/gi";
import { FiLogOut } from "react-icons/fi";
import { useState } from "react";
import CalendlyPopupButton from "../CalendlyPopupButton.tsx";
import weblessLogo from "../../assets/images/webless_logo.jpg";
import { useDataContext } from "../../context/DataContext.tsx";
import { useCookies } from "react-cookie";

const WeblessNavbar = () => {
  const [isOptionsOpen, setIsOptionsOpen] = useState(false);
  const { setUser } = useDataContext();
  const navigate = useNavigate();
  const [, , removeToken] = useCookies(["token"]);
  const [, , removeUserRole] = useCookies(["userRole"]);

  const handleLogout = () => {
    // First navigate to home
    navigate("/");
    
    // Reset user state to initial values (undefined instead of empty string)
    setUser({
      token: undefined,
      role: undefined,
      userId: undefined
    });
    
    // Clear cookies using react-cookie
    removeToken("token", { path: "/", sameSite: "strict" });
    removeUserRole("userRole", { path: "/", sameSite: "strict" });
    
    // Close menu if open
    setIsOptionsOpen(false);
  };

  return (
    <nav className="navbar-container">
      <div className="navbar-left-container">
        <div className="nav-logo-container">
          <a href="https://webless.ai/" target="_blank">
            <img src={weblessLogo} alt="Webless Logo" />
          </a>
        </div>
      </div>
      <div className="navbar-right-container">
        <CalendlyPopupButton className="filled" />
        <button onClick={handleLogout} className="icon-button" title="Logout">
          <FiLogOut />
        </button>
        <div className="menu-container">
          <GiHamburgerMenu
            className="menu-icon"
            onClick={() => setIsOptionsOpen((prev) => !prev)}
          />
          {isOptionsOpen && (
            <div className="menu-options">
              <CalendlyPopupButton />
              <button onClick={handleLogout} className="icon-button mobile">
                <FiLogOut /> Logout
              </button>
            </div>
          )}
        </div>
      </div>
    </nav>
  );
};

export default WeblessNavbar;
