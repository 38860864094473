import React from "react";
import "./Welcome.css";
import weblessLogo from "../../assets/images/webless_logo.jpg";

const Welcome = () => {
  return (
    <div className="welcome-page">
      <div className="welcome-content">
        <img src={weblessLogo} alt="Webless Logo" className="welcome-logo" />
        <h1>Welcome to Webless</h1>
        <p>Your AI-powered search solution</p>
        <div className="features">
          <div className="feature">
            <h3>Intelligent Search</h3>
            <p>Find exactly what you need with AI-powered search</p>
          </div>
          <div className="feature">
            <h3>Control & Feedback</h3>
            <p>Improve search accuracy through continuous feedback</p>
          </div>
          <div className="feature">
            <h3>Performance Tracking</h3>
            <p>Monitor search usage and understand user behavior</p>
          </div>
          <div className="feature">
            <h3>Secure Access</h3>
            <p>Role-based access control for your data</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Welcome;
