import React from "react";
import { MetricsDashboard } from "../../components/MetricsDashboard/MetricsDashboard.tsx";
import "../../components/MetricsDashboard/MetricsDashboard.css";

const DASHBOARD_ID = "c33b55b6-76f6-4c53-ae66-54e6b582a505";

const BlueshiftMetrics: React.FC = () => {
  return (
    <MetricsDashboard
      dashboardId={DASHBOARD_ID}
      title="Blueshift Metrics Dashboard"
      containerClassName="blueshift-metrics-container"
    />
  );
};

export default BlueshiftMetrics;
