import React, { useState } from "react";
import SearchBar from "../../components/SearchBar/SearchBar.tsx";
import { useDataContext } from "../../context/DataContext.tsx";
import "./Home.css";
import SearchTile from "../../components/SearchTile/SearchTile.tsx";
import Masonry from "react-masonry-css";
import { useSummaryContext } from "../../context/SummaryContext.tsx";
import HorizontalLoader from "../../components/Loaders/HorizontalLoader/HorizontalLoader.tsx";
import ShowMoreText from "../../components/ShowMoreText/ShowMoreText.tsx";
import useWindowWidth from "../../hooks/useWindowWidth.tsx";
import EditButton from "../../components/EditButton/EditButton.tsx";
import Button from "../../components/Button/Button.tsx";
import CTASidePannel from "../../components/SidePannel/CTASidePannel/CTASidePannel.tsx";
import SummarySidePannel from "../../components/SidePannel/SummarySidePannel/SummarySidePannel.tsx";
import Spinner from "../../components/Loaders/Spinner/Spinner.tsx";

const Home = () => {
  const { data, loading, requestId, fetchData } = useDataContext();
  const { summary, setSummary, loadingSummary } = useSummaryContext();
  const [generationType, setGenerationType] = useState<string>("");
  const { width } = useWindowWidth();
  const [showEditCTA, setShowEditCTA] = useState<boolean>(false);
  const [showSuggestedQuestion, setShowSuggestedQuestion] =
    useState<boolean>(false);
  const [showEditSummary, setShowEditSummary] = useState<boolean>(false);
  const handleTileClick = (link: string, tileId: string) => {
    // sendEvent({
    //   event_type: "tile_click",
    //   session_id: sessionStorage.getItem("sessionId") || "",
    //   page: "home_page",
    //   company: "blueshift",
    //   request_id: requestId,
    //   query: question,
    //   tile_id: tileId,
    //   location: "floating",
    // });
    window.open(link, "_blank");
  };

  const resetState = () => {
    setSummary("");
    setGenerationType("");
  };

  return (
    <div className="homepage">
      <CTASidePannel
        showEditCTA={showEditCTA}
        setShowEditCTA={setShowEditCTA}
      />
      <SummarySidePannel
        showEditSummary={showEditSummary}
        setShowEditSummary={setShowEditSummary}
      />

      {/* <div
        className={`tile-edit-slider-container-wrapper  ${showEditCTA ? "show" : ""}`}
        onClick={() => setShowEditCTA(false)}
      >
        <div
          className={`cta-slider-container ${showEditCTA ? "show" : ""}`}
          onClick={(e) => e.stopPropagation()}
        >
          <div className="slider-heading-container">
            <p className="slider-heading">Edit CTA</p>
            <MdClose
              className="slider-close-button"
              onClick={() => setShowEditCTA(false)}
            />
          </div>
          <hr className="slider-hr" />
          <div className="edit-section">
            <table className="cta-table">
              <thead>
                <tr>
                  <td></td>
                  <td></td>
                  <td>Title</td>
                  <td>URL</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <VscListFlat />
                  </td>
                  <td>Current CTA</td>
                  <td>Get Started</td>
                  <td>https://www.google.com</td>
                </tr>
                <tr>
                  <td>
                    <VscListFlat />
                  </td>
                  <td>Suggested CTA</td>
                  <td>
                    <input type="text" placeholder="CTA Title" />
                  </td>
                  <td>
                    <input type="text" placeholder="Paste URL" />
                  </td>
                </tr>
              </tbody>
            </table>
            <Button text="Save" onClick={() => {}} />
          </div>
        </div>
      </div> */}
      <div className="top-container">
        <SearchBar
          fetchData={fetchData}
          // text={text}
          // setText={setText}
        />
        {/* <div className="suggested-question-container">
          <SuggestedQuestion
            text="How do I forecast consumption-based products in Salesforce?"
            onEdit={() => {}}
            onQuestionClick={fetchData}
          />
          <SuggestedQuestion
            text="How does revVana differ from Clari?"
            onEdit={() => {}}
            onQuestionClick={fetchData}
          />
          <SuggestedQuestion
            text="How do I forecast consumption-based products in Salesforce?"
            onEdit={() => {}}
            onQuestionClick={fetchData}
          />
          <SuggestedQuestion
            text="Why can't I use Salesforce's in-house tools to forecast revenue?"
            onEdit={() => {}}
            onQuestionClick={fetchData}
          />
        </div> */}

        {loadingSummary || loading ? (
          <HorizontalLoader />
        ) : summary.length > 0 ? (
          <div className="summary-container-wapper">
            <p className="result-desc default">
              {width < 765 ? (
                <ShowMoreText
                  text={summary}
                  maxLength={200}
                  type={generationType}
                />
              ) : generationType == "bullet_point" ? (
                <ul className="bullet-point-summary">
                  {summary.split("###").map((item) => {
                    let temp = item.trim();
                    if (temp.length > 0) {
                      return <li>{item}</li>;
                    }
                  })}
                </ul>
              ) : (
                summary
              )}
            </p>
            {!loadingSummary && !loading && summary.length > 5 && (
              <div className="summary-edit-button-wrapper">
                <EditButton
                  text="Edit Response"
                  onClick={() => {
                    setShowEditSummary(true);
                  }}
                />
              </div>
            )}
          </div>
        ) : null}
        {summary.length > 0 && (
          <div className="cta-container-wrapper">
            <Button
              text={data.sales_cta.text}
              onClick={() => {
                window.open(data.sales_cta.url, "_blank");
              }}
            />
            <EditButton
              text="Edit CTA"
              onClick={() => {
                setShowEditCTA(true);
              }}
            />
          </div>
        )}
      </div>

      <div className="tiles-container">
        {loading ? (
          <div className="spinner-container-wrapper">
            <Spinner />
          </div>
        ) : (
          <Masonry
            breakpointCols={{ default: 3, 700: 2, 500: 1 }}
            className="my-masonry-grid"
            columnClassName="masonry-grid-column"
          >
            {data.responses.map((item) => {
              return (
                <SearchTile
                  key={item.id}
                  title={item.title}
                  imgUrl={item.image}
                  desc={item.description}
                  link={item.link}
                  tileId={item.id}
                  handleTileClick={handleTileClick}
                />
              );
            })}
          </Masonry>
        )}
      </div>
    </div>
  );
};

export default Home;