import React from "react";
import { MetricsDashboard } from "../../components/MetricsDashboard/MetricsDashboard.tsx";
import "../../components/MetricsDashboard/MetricsDashboard.css";

const DASHBOARD_ID = "c76b7bd8-7e27-41d0-9033-e581eb76be0d"; 

const InkleMetrics: React.FC = () => {
  return (
    <MetricsDashboard
      dashboardId={DASHBOARD_ID}
      title="Inkle Metrics Dashboard"
      containerClassName="inkle-metrics-container"
    />
  );
};

export default InkleMetrics;
